import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardMain from '../views/File_Dashboard/Dashboard_Main.vue'
import DashboardDashboard from '../views/File_Dashboard/Dashboard_Dashboard.vue'
import CycleMain from '../views/File_Cycle/Cycle_Main.vue'
import LoginMain from '../views/File_Login/Login_Main.vue'
import MyPaymentsMain from '../views/File_MyPayments/MyPayments_Main.vue'
import SentPaymentsMain from '../views/File_SentPayments/SentPayments_Main.vue'
import PaymentMain from '../views/File_Payment/Payment_Main.vue'
import SpecialtyMain from '../views/File_Specialty/Specialty_Main.vue'
import StudentsMain from '../views/File_Students/Students_Main.vue'
import SentPaymentsValidate from '../views/File_SentPayments/SentPayments_Validate.vue'
import FinanceMain from "../views/File_Finance/Finance_Main.vue"
import ErrorMessage from '../views/File_Error/Error_Message.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    beforeEnter: autorizationLogin,
    component: LoginMain
  },
  {
    path: '/',
    component: DashboardMain,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: 'dashboard' // Redirigir a la ruta hija por defecto
      },
      {
        path: 'cycle',
        name: 'cycle',
        component: CycleMain
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardDashboard
      },
      {
        path: 'finance',
        name: 'finance',
        component: FinanceMain
      },
      {
        path: 'myPayments',
        name: 'myPayments',
        component: MyPaymentsMain
      },
      {
        path: 'payment',
        name: 'payment',
        component: PaymentMain
      },
      {
        path: 'sentPayments',
        name: 'sentPayments',
        component: SentPaymentsMain
      },
      {
        path: 'students',
        name: 'students',
        component: StudentsMain
      },
      {
        path: 'specialty/:yearCycle/:nameCycle/:idCycle',
        name: 'specialty',
        component: SpecialtyMain
      },
      {
        path: 'validate/:idPayment',
        name: 'validate',
        component: SentPaymentsValidate
      }
    ]
  },
  {
    path: '/error',
    name: 'errorMessage',
    component: ErrorMessage
  },
  {
    path: "*",
    redirect: "/"
  },
  {
    path: '/:catchAll(.*)',
    redirect: "/"
  },
]

function autorizationLogin(to, from, next) {
  if (localStorage.getItem("authenticated") === 'true') next({ name: 'dashboard' })
  else next()
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = (localStorage.getItem("authenticated") === 'true');
  //const isAuthenticated = true;
  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router
