<template>
  <v-dialog v-model="dialogData" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-chip-group>
        <v-chip
          @click="loadPaymentDetail()"
          color="info"
          v-on="on"
          v-bind="attrs"
        >
          <b>{{
            dataStudent.paymentCount.count +
            "/" +
            dataStudent.paymentCount.total
          }}</b>
        </v-chip>
      </v-chip-group>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Información detallada de Pagos</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="enrollment"
            :items-per-page="enrollment.length"
            class="elevation-1 mt-3 rounded-lg"
            hide-default-footer
          >
            <template slot="item.amount" slot-scope="{ item }">
              {{ "S/. " + item.cost }}
            </template>
            <template slot="item.state" slot-scope="{ item }">
              <v-chip :color="item.status.color">
                <b>{{ item.status.name }}</b>
              </v-chip>
            </template>
            <template slot="item.actions" slot-scope="{ item }">
              <StudentPaymentView :dataPayment="item" />
              <StudentPaymentEdit
                @loadPaymentDetail="loadPaymentDetail()"
                :dataRow="item"
              />
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="dialogData = false"
          ><b>Volver</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
        
        <script>
import StudentPaymentEdit from "./Students_PaymentsEdit.vue";
import StudentPaymentView from "./Students_PaymentsView.vue";
export default {
  name: "StudentsPaymentsInformation",
  components: {
    StudentPaymentEdit,
    StudentPaymentView,
  },
  props: ["dataStudent"],
  data() {
    return {
      dialogData: false,
      enrollment: [],
      pensions: [],
      headers: [
        { text: "Concepto", value: "order" },
        { text: "Monto", value: "amount" },
        { text: "Estado", value: "state" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  created() {},
  methods: {
    async loadPaymentDetail() {
      await this.$axios
        .get(
          "/enrollment-speciality/" + this.dataStudent.id + "/payment-detail",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.enrollment = [response.data.detailEnroll];
          this.enrollment[0] = {
            ...this.enrollment[0],
            order: "Matrícula",
          };
          this.pensions = response.data.allDetailPension;
          for (let i = 0; i < this.pensions.length; i++) {
            this.pensions[i].order = "Pensión " + this.pensions[i].order;
          }
          this.enrollment = this.enrollment.concat(this.pensions);
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
  },
};
</script>