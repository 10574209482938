<template>
  <v-container class="max-width-container" fluid>
    <v-breadcrumbs :items="itemsRouter" large></v-breadcrumbs>
    <v-card class="rounded-lg">
      <v-card-title class="display-1 primary--text"
        ><b
          >ESPECIALIDADES DE "{{
            $route.params.yearCycle +
            " CICLO " +
            $route.params.nameCycle.toUpperCase()
          }}"</b
        ><v-spacer></v-spacer>
        <TableAdd @loadTable="loadTable()"></TableAdd>
      </v-card-title>

      <v-card-text>
        <TableSpecialty ref="tableData" />
      </v-card-text>
    </v-card>
  </v-container>
</template>
    <script>
import router from "@/router";
import TableSpecialty from "./Specialty_TableSpecialty.vue";
import TableAdd from "./Specialty_TableAdd.vue";
export default {
  name: "SpecialtyMain",
  components: {
    TableSpecialty,
    TableAdd,
  },
  data() {
    return {
      itemsRouter: [
        {
          text: "Ciclo",
          disabled: false,
          to: "/cycle",
        },
        {
          text: "Especialidad",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    goToCycle() {
      router.push({ name: "cycle" });
    },
    loadTable() {
      this.$refs.tableData.loadTable();
    },
  },
};
</script>