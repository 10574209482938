<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" color="primary" width="300" dark app>
      <!-- -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="display-1"><b>USEFC</b> </v-list-item-title>
          <small> Unidad de Segunda Especialidad y Formación Continua </small>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list rounded>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar absolute color="primary" height="75" dark app>
      <!-- -->
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title v-if="!drawer"><b>USEFC</b></v-app-bar-title>

      <v-spacer></v-spacer>

      <!--<router-link :to="'/sentPayments'">
        <v-badge :color="checkColor(totalPayments)" overlap :content="totalPayments">
          <v-avatar color="secondary" size="46">
            <v-icon>mdi-email-outline</v-icon>
          </v-avatar>
        </v-badge>
      </router-link>

      <v-spacer></v-spacer>
-->
      <v-menu bottom min-width="250px" rounded offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-large v-bind="attrs" v-on="on">
            <v-avatar color="secondary" size="46">
              <span class="white--text text-h6">{{ getTextIconPerson() }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar class="mb-1" color="secondary" size="46">
                <span class="white--text text-h6">{{
                  getTextIconPerson()
                }}</span>
              </v-avatar>
              <h3 class="mx-3">
                {{ dataPerson.name + " " + dataPerson.lastnameFather }}
              </h3>
              <p class="text-caption mt-1">
                {{ dataPerson.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn @click="logout()" depressed rounded text>
                <b>Cerrar Sesión</b>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view />
      </v-container>
    </v-main>

    <!--<v-footer padless app>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>USEFC</strong>
      </v-col>
    </v-footer>-->
  </v-app>
</template>

<script>
import router from "@/router";
export default {
  name: "DashboardMain",
  data() {
    return {
      selectedItem: 0,
      itemsPerson: [
        { text: "Cerrar Sesión", icon: "mdi-logout", action: this.logout },
      ],
      items: [],
      drawer: true,
      dataPerson: {},
      totalPayments: 0,
    };
  },
  created() {
    this.getDataPerson();
    this.loadNavigation();
    this.getTotalPayments();
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("authenticated");
      localStorage.removeItem("key");
      localStorage.removeItem("information");
      router.push({ name: "login" });
    },
    loadNavigation() {
      if (localStorage.getItem("key") == 1683996999887809) {
        this.items = [
          {
            title: "Principal",
            icon: "mdi-view-dashboard",
            route: "/dashboard",
          },
          {
            title: "Pago",
            icon: "mdi-receipt-text-check-outline",
            route: "/payment",
          },
          {
            title: "Mis Pagos",
            icon: "mdi-email-fast-outline",
            route: "/myPayments",
          },
        ];
      } else if (localStorage.getItem("key") == 1683996966187910) {
        this.items = [
          {
            title: "Principal",
            icon: "mdi-view-dashboard",
            route: "/dashboard",
          },
          {
            title: "Recibido",
            icon: "mdi-message-text-clock-outline",
            route: "/sentPayments",
          },
          { title: "Ciclos", icon: "mdi-package", route: "/cycle" },
          {
            title: "Estudiantes",
            icon: "mdi-account-group",
            route: "/students",
          },
          {
            title: "Finanzas",
            icon: "mdi-finance",
            route: "/finance",
          },
        ];
      } else {
        this.items = [
          {
            title: "Principal",
            icon: "mdi-view-dashboard",
            route: "/dashboard",
          },
          {
            title: "Recibido",
            icon: "mdi-message-text-clock-outline",
            route: "/sentPayments",
          },
          {
            title: "Pago",
            icon: "mdi-receipt-text-check-outline",
            route: "/payment",
          },
          {
            title: "Mis Pagos",
            icon: "mdi-email-fast-outline",
            route: "/myPayments",
          },
          { title: "Ciclos", icon: "mdi-package", route: "/cycle" },
          {
            title: "Estudiantes",
            icon: "mdi-account-group",
            route: "/students",
          },
          {
            title: "Finanzas",
            icon: "mdi-finance",
            route: "/finance",
          },
        ];
      }
    },
    getDataPerson() {
      this.dataPerson = JSON.parse(localStorage.getItem("information"));
    },
    getTextIconPerson() {
      return (
        this.dataPerson.name.charAt(0).toUpperCase() +
        this.dataPerson.lastnameFather.charAt(0).toUpperCase()
      );
    },

    // Total de pagos pendientes de validar solo si no eres estudiante
    async getTotalPayments() {
      if (localStorage.getItem("key") != 1683996999887809) {
        await this.$axios
          .get("/requisition-pay/total-sent", {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          })
          .then((response) => {
            this.totalPayments = response.data.content;
          })
          .catch((error) => {
            this.$queryError(error);
          });
      }
    },

    // Verificar color segun el numero de pagos
    checkColor(total) {
      if (total == 0) return "green";
      else if (total <= 25) return "orange";
      else return "red";
    },
  },
};
</script>