<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1 mt-3 rounded-lg"
    >
      <template slot="item.index" slot-scope="{ item }">
        <b>{{ getIndex(item) }}</b>
      </template>
      <template slot="item.cui" slot-scope="{ item }">
        <div v-if="item.person.cui != null">
          {{ item.person.cui }}
        </div>
        <div v-else>
          <v-chip color="error" outlined> Vacío </v-chip>
        </div>
      </template>
      <template slot="item.name" slot-scope="{ item }">
        {{
          item.person.lastnameFather +
          " " +
          item.person.lastnameMother +
          ", " +
          item.person.name
        }}
      </template>
      <template slot="item.payments" slot-scope="{ item }">
        <PaymentsInformation :dataStudent="item" />
      </template>
      <template slot="item.edit" slot-scope="{ item }">
        <StudentsEdit @loadTable="loadTable()" :dataStudent="item" />
      </template>
    </v-data-table>
  </div>
</template>
    
    <script>
import PaymentsInformation from "./Students_PaymentsInformation.vue";
import StudentsEdit from "./Students_TableEdit.vue";
export default {
  name: "TableStudents",
  components: {
    PaymentsInformation,
    StudentsEdit,
  },
  props: ["idCycle", "idSpecialty"],
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Nombres", value: "name" },
        { text: "Pagos", value: "payments" },
        { text: "DNI", value: "person.dni" },
        { text: "CUI", value: "cui" },
        { text: "Celular", value: "person.phone" },
        { text: "Correo", value: "person.email" },
        { text: "Editar", value: "edit" },
      ],
      desserts: [],
    };
  },
  created() {},
  methods: {
    async loadTable() {
      await this.$axios
        .get(
          "/academic-cycle/" +
            this.idCycle +
            "/speciality/" +
            this.idSpecialty +
            "/enroll?perPage=100",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.desserts = response.data.allItems;
        })
        .catch((error) => {
          this.$queryError(error);
          this.$toast.error(error.response.data.content);
        });
    },
    // Limpiar los datos de la tabla
    clearTable() {
      this.desserts = [];
    },
    getIndex(item) {
      // Obtener el índice del elemento en la lista
      const index = this.desserts.indexOf(item);
      // Agregar 1 al índice para obtener el número correspondiente
      return index + 1;
    },
  },
};
</script>