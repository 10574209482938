<template>
  <div>
    <v-container class="max-width-container" fluid>
      <v-card class="rounded-lg">
        <v-card-title class="display-1 primary--text"
          ><b>MIS PAGOS</b><v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <MyPaymentsTable />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
        <script>
import MyPaymentsTable from "./MyPayments_Table.vue";
export default {
  name: "MyPaymentsMain",
  components: {
    MyPaymentsTable,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>