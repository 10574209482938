import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                //primary: '#1976D2',
                //secondary: '#424242',
                primary: '#5e151d',
                secondary: '#606060',
                accent: '#82B1FF',
                error: '#BC3030',//'#FF5252',
                info: '#167DCF',//'#2196F3',
                success: '#2FA434',//'#4CAF50',
                warning: '#FFBF00',//'#FFC107',
            },
        },
        // Cambia la fuente por defecto a Roboto
        // Agrega la familia de fuente a la propiedad "fontFamily" en la sección "options"
        // Puedes personalizar las variantes de fuente a utilizar en cada tipo de texto
        // utilizando la propiedad "fontStyle"
        // Ejemplo: "fontStyle: { display4: ['Roboto', 'sans-serif'] }"
        typography: {
            fontFamily: 'Roboto'
        }
    },
});
