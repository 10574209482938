<template>
  <v-dialog v-model="dialogEdit" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        color="warning"
        @click="getDataRow()"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Editar Ciclo</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container>
          <MessageError ref="messageError" />
          <v-form>
            <v-text-field
              v-model="formData.name"
              label="Nombre:"
              type="text"
              :rules="empty"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.year"
              label="Año:"
              type="number"
              :rules="empty"
              outlined
              clearable
              required
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogEdit = false">
          <b>Cerrar</b>
        </v-btn>
        <v-btn color="success" text @click="updateDataRow()"
          ><b>Enviar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import MessageError from "@/components/Message_Error.vue";
export default {
  name: "CycleTableEdit",
  props: ["cycleRow"],
  components: {
    MessageError,
  },
  data() {
    return {
      dialogEdit: false,
      empty: [(val) => (val || "").length > 0 || "Este campo es requerido"],
      formData: {},
    };
  },
  created() {},
  methods: {
    getDataRow() {
      this.formData.name = this.cycleRow.name;
      this.formData.year = this.cycleRow.year;
      this.$nextTick(() => {
        if (this.$refs.messageError) {
          this.$refs.messageError.hideAlertError();
        }
      });
    },
    async updateDataRow() {
      await this.$axios
        .put("/academic-cycle/" + this.cycleRow.id, this.formData, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.dialogEdit = false;
          this.$emit("loadTable");
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
          this.$refs.messageError.showAlertError(error.response.data.content);
        });
    },
  },
};
</script>