<template>
  <v-container class="max-width-container" fluid>
    <v-card class="rounded-lg">
      <v-card-title class="display-1 primary--text"
        ><b>DASHBOARD</b>
      </v-card-title>
      <v-card-text>
        <v-sheet v-if="specialties.length != 0" class="mx-auto">
          <v-slide-group
            class="pa-4"
            active-class="secondary lighten-5"
            show-arrows
          >
            <v-slide-item
              v-for="(item, index) in specialties"
              :key="index"
              v-slot="{ active, toggle }"
            >
              <v-card
                :color="active ? undefined : 'grey lighten-3'"
                class="ma-4"
                height="300"
                width="250"
                @click="toggle"
              >
                <v-img
                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="150px"
                ></v-img>

                <v-card-title> {{ item.specialityName }} </v-card-title>

                <v-card-subtitle>
                  CICLO {{ item.academicCycleName }}
                </v-card-subtitle>
                <v-row class="fill-height" align="center" justify="center">
                  <v-scale-transition>
                    <v-icon
                      v-if="active"
                      color="white"
                      size="48"
                      :v-text="'mdi-close-circle-outline'"
                    ></v-icon>
                  </v-scale-transition>
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "DashboardDashboard",
  data() {
    return {
      specialties: [],
    };
  },
  created() {
    this.loadSpecialties();
  },
  methods: {
    async loadSpecialties() {
      await this.$axios
        .get("/enrollment-speciality/my", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.specialties = response.data.allItems;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
  },
};
</script>