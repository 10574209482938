<template>
  <v-dialog v-model="dialogEdit" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        color="warning"
        @click="getDataRow()"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Editar Especialidad</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container>
          <MessageError ref="messageError" />
          <v-form>
            <!-- Dialogo de fecha de inicio -->
            <v-dialog
              ref="dialogStart"
              v-model="modalStart"
              :return-value.sync="formData.startDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.startDate"
                  label="Fecha de inicio:"
                  prepend-icon="mdi-calendar"
                  :rules="empty"
                  readonly
                  outlined
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formData.startDate"
                color="primary"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalStart = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogStart.save(formData.startDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- Dialogo de fecha de fin -->
            <v-dialog
              ref="dialogFinal"
              v-model="modalFinal"
              :return-value.sync="formData.finalDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.finalDate"
                  label="Fecha de fin:"
                  prepend-icon="mdi-calendar"
                  :rules="empty"
                  readonly
                  outlined
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formData.finalDate"
                color="primary"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFinal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogFinal.save(formData.finalDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-text-field
              v-model="formData.enrollCost"
              label="Costo de Matrícula"
              prepend-icon="mdi-alpha-m-circle-outline"
              type="number"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.pensionCost"
              label="Costo de Pensión"
              prepend-icon="mdi-alpha-p-circle-outline"
              type="number"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.numberCourses"
              label="Cantidad de cursos"
              prepend-icon="mdi-animation-outline"
              type="number"
              outlined
              clearable
              required
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogEdit = false">
          <b>Cerrar</b>
        </v-btn>
        <v-btn color="success" text @click="updateDataRow()"
          ><b>Enviar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
    <script>
import MessageError from "@/components/Message_Error.vue";
export default {
  name: "SpecialtyTableCycle",
  props: ["specialtyRow"],
  components: {
    MessageError,
  },
  data() {
    return {
      modalStart: false,
      modalFinal: false,
      dialogEdit: false,
      empty: [(val) => (val || "").length > 0 || "Este campo es requerido"],
      formData: {},
    };
  },
  created() {},
  methods: {
    getDataRow() {
      this.getDataSpecialty();
      this.$nextTick(() => {
        if (this.$refs.messageError) {
          this.$refs.messageError.hideAlertError();
        }
      });
    },

    // Obtiene los datos detallados de la especialidad
    async getDataSpecialty() {
      await this.$axios
        .get(
          "/academic-cycle/" +
            this.$route.params.idCycle +
            "/speciality/" +
            this.specialtyRow.id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.formData = {
            enrollCost: response.data.enrollCost,
            finalDate: response.data.finalDate,
            pensionCost: response.data.pensionCost,
            startDate: response.data.startDate,
            numberCourses: response.data.numberCourses,
          };
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },

    async updateDataRow() {
      await this.$axios
        .put(
          "/academic-cycle/" +
            this.$route.params.idCycle +
            "/speciality/" +
            this.specialtyRow.id,
          this.formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.dialogEdit = false;
          this.$emit("loadTable");
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
          this.$refs.messageError.showAlertError(error.response.data.content);
        });
    },
  },
};
</script>
