import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // Aquí puedes declarar las variables globales que quieras guardar en el store
    state: false,
  },
  mutations: {
    // Aquí se definen las mutaciones que pueden actualizar el estado del store
    SET_STATE(state, nombre) {
      state.nombre = nombre
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('authenticated');
      localStorage.removeItem('key');
      localStorage.removeItem('information');
    }
  },
  actions: {
    // Aquí se definen las acciones que pueden llamar a una o varias mutaciones para actualizar el estado
    /*guardarDatos({ commit }, datos) {
      commit('SET_NOMBRE', datos.nombre)
      commit('SET_EDAD', datos.edad)
      commit('SET_CORREO', datos.correo)
    },*/
  },
  getters: {
    // Aquí se definen los getters que permiten acceder al estado desde los componentes
    /*getState(state) {
      return `${state.nombre} (${state.edad} años) - ${state.correo}`
    },*/
  },
})
