<template>
  <div>
    <v-stepper v-model="flag" vertical>
      <v-stepper-step :complete="flag > 1" step="1">
        Seleccionar Especialidad
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="grey lighten-4" class="mb-12">
          <v-card-text>
            <v-alert
              v-if="AlertView"
              class="text-left"
              border="left"
              dismissible
              text
              icon="mdi-alert-circle"
              type="info"
              >Tiene un pago pendiente de revisión en esta Especialidad
              <v-btn
                outlined
                small
                @click="$router.push({ name: 'myPayments' })"
                color="info"
              >
                <v-icon left>mdi-eye-outline </v-icon><b>Mis Pagos</b>
              </v-btn></v-alert
            >
            <v-select
              v-model="selectedSpecialty"
              :items="specialties"
              item-text="specialityName"
              item-value="id"
              label="Seleccione la Especialidad"
              background-color="white"
              prepend-inner-icon="mdi-pencil-plus-outline"
              outlined
              hide-details
              chips
              small-chips
            ></v-select>
          </v-card-text>
        </v-card>
        <v-btn
          color="info"
          :disabled="selectedSpecialty == ''"
          @click="verifyPayment()"
          ><b>CONTINUAR</b></v-btn
        >
      </v-stepper-content>

      <v-stepper-step :complete="flag > 2" step="2">
        Subir Imagen y datos de Voucher
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card color="grey lighten-4" class="mb-12">
          <v-card-text>
            <v-file-input
              v-model="selectedImage"
              accept=".jpg,.jpeg,.png"
              label="Imagen de Voucher"
              background-color="white"
              @change="previewImage"
              hide-details
              outlined
              chips
            ></v-file-input>
            <br />
            <v-row v-if="selectedImage != null">
              <v-col lg="6" sm="12">
                <h2>Imagen de Voucher</h2>
                <v-img
                  class="mt-5"
                  v-if="previewURL"
                  :src="previewURL"
                  height="400"
                  contain
                ></v-img>
              </v-col>
              <v-col lg="6" sm="12">
                <v-form>
                  <h2>Información del Voucher</h2>
                  <v-text-field
                    v-model="date"
                    :rules="empty"
                    :error-messages="date ? '' : 'Campo requerido'"
                    class="mt-5"
                    label="Ingrese Fecha de pago"
                    type="date"
                    background-color="white"
                    outlined
                    clearable
                    required
                  ></v-text-field>
                  <v-select
                    v-model="finalcialId"
                    :rules="empty"
                    :error-messages="finalcialId ? '' : 'Campo requerido'"
                    :items="entities"
                    item-text="text"
                    item-value="value"
                    label="Seleccione Banco"
                    background-color="white"
                    outlined
                    chips
                    small-chips
                  ></v-select>
                  <v-text-field
                    v-model="operationNumber"
                    :rules="empty"
                    :error-messages="operationNumber ? '' : 'Campo requerido'"
                    label="Número de operación"
                    type="number"
                    background-color="white"
                    outlined
                    clearable
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="amount"
                    :rules="empty"
                    :error-messages="amount ? '' : 'Campo requerido'"
                    label="Monto de Voucher"
                    type="number"
                    background-color="white"
                    outlined
                    clearable
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="comment"
                    label="Comentario"
                    type="text"
                    messages="* Opcional"
                    background-color="white"
                    outlined
                    clearable
                    required
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-btn
          color="info"
          :disabled="
            selectedImage == null ||
            date == '' ||
            date == null ||
            finalcialId == '' ||
            operationNumber == 0 ||
            amount == 0 ||
            operationNumber == null ||
            amount == null
          "
          @click="
            flag = 3;
            loadPayments();
          "
          ><b>CONTINUAR</b></v-btn
        >
        <v-btn color="primary" text @click="flag = 1"><b>VOLVER</b></v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="flag > 3" step="3">
        Calculo de conceptos de pago
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card color="grey lighten-4" class="mb-12">
          <v-card-text>
            <v-alert type="success">
              <b>MONTO DE VOUCHER: S/{{ sendData.amount }}</b>
            </v-alert>
            <v-alert
              v-for="(field, index) in viewCalculatePayments"
              :key="index"
              type="info"
              outlined
              text
              dense
            >
              <b>{{ field.field }}</b>
            </v-alert>
          </v-card-text>
        </v-card>
        <v-btn
          color="info"
          @click="flag = 4"
          :disabled="payments.allDebtPension == []"
          ><b>CONTINUAR</b></v-btn
        >
        <v-btn color="primary" text @click="flag = 2"><b>VOLVER</b></v-btn>
      </v-stepper-content>

      <v-stepper-step step="4"> Verificar información llenada</v-stepper-step>
      <v-stepper-content step="4">
        <v-card color="grey lighten-4" class="mb-12">
          <v-card-text>
            <v-container>
              <v-row><h3>ESPECIALIDAD</h3></v-row>
              <v-row>
                <v-col
                  ><v-select
                    label="Especialidad"
                    :value="selectedSpecialty"
                    :items="specialties"
                    item-text="specialityName"
                    item-value="id"
                    background-color="white"
                    hide-details
                    filled
                    shaped
                    readonly
                  ></v-select></v-col
              ></v-row>
              <v-row><h3>DATOS DE VOUCHER</h3></v-row>
              <v-row>
                <v-col
                  ><v-text-field
                    v-if="selectedImage != null"
                    label="Voucher"
                    background-color="white"
                    :value="selectedImage.name"
                    readonly
                    filled
                    shaped
                  ></v-text-field
                  ><v-text-field
                    label="Fecha de pago"
                    background-color="white"
                    :value="date"
                    readonly
                    filled
                    shaped
                  ></v-text-field
                  ><v-select
                    :items="entities"
                    item-text="text"
                    item-value="value"
                    label="Banco"
                    :value="finalcialId"
                    background-color="white"
                    readonly
                    filled
                    shaped
                  ></v-select>
                  <v-text-field
                    label="Número de operación"
                    background-color="white"
                    :value="operationNumber"
                    readonly
                    filled
                    shaped
                  ></v-text-field
                  ><v-text-field
                    label="Monto de voucher"
                    background-color="white"
                    :value="sendData.amount"
                    readonly
                    filled
                    shaped
                  ></v-text-field
                  ><v-text-field
                    label="Comentario"
                    background-color="white"
                    :value="comment"
                    hide-details
                    readonly
                    filled
                    shaped
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row><h3>CONCEPTOS DE PAGO</h3></v-row>
              <v-row v-if="payments.debtEnroll != null">
                <v-col v-if="payments.debtEnroll.residue != 0"
                  ><v-text-field
                    label="Monto de pago de Matrícula"
                    background-color="white"
                    :value="sendData.payEnroll.amount"
                    hide-details
                    readonly
                    filled
                    shaped
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row
                v-for="(field, index) in sendData.allPayPension"
                :key="index"
                ><v-col
                  ><v-select
                    label="Pensión"
                    :value="field.paymentScheduleId"
                    :items="payments.allDebtPension"
                    item-text="reference"
                    item-value="id"
                    background-color="white"
                    hide-details
                    filled
                    shaped
                    readonly
                  ></v-select></v-col
                ><v-col
                  ><v-text-field
                    label="Monto de pago de Pensión"
                    background-color="white"
                    :value="field.amount"
                    hide-details
                    readonly
                    filled
                    shaped
                  ></v-text-field></v-col></v-row
            ></v-container>
          </v-card-text>
        </v-card>
        <v-btn color="success" @click="sendPayment"><b>ENVIAR</b></v-btn>
        <v-btn color="primary" text @click="flag = 3"><b>VOLVER</b></v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>
  <script>
import router from "@/router";
export default {
  data() {
    return {
      // Indicar de paso en el que me encuentro
      flag: 1,
      // Especialidad seleccionada
      selectedSpecialty: "",

      specialties: [],
      entities: [],
      payments: {},
      selectedImage: null,
      previewURL: null,
      AlertView: false,
      empty: [(v) => !!v || "Campo requerido"],

      // Referencia a la imagen
      file: null,
      // Datos de voucher
      amount: 0,
      date: "",
      operationNumber: 0,
      finalcialId: "",
      comment: "",

      // JSON envio
      sendData: {
        allPayPension: [],
        amount: 0,
        payEnroll: {
          amount: 0,
        },
      },
      // Informacion mostrada de calculo de pago
      viewCalculatePayments: [],
    };
  },
  created() {
    this.loadSpecialties();
    this.getEntities();
  },
  methods: {
    // Obtiene las entidades financieras - bancos
    async getEntities() {
      await this.$axios
        .get("/financial-entity", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.entities = response.data;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },

    // Verifica que no se tenga un pago pendiente de validacion en la especialidad
    async verifyPayment() {
      this.AlertView = false;
      await this.$axios
        .get(
          "/enrollment-speciality/" +
            this.selectedSpecialty +
            "/payment-enabled",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          if (!response.data.enabled) {
            this.AlertView = true;
            this.$toast.warning("No puede continuar con la acción");
          } else this.flag = 2;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    // Sube la imagen al servidor de archivos
    async uploadFile() {
      return new Promise((resolve, reject) => {
        // Crea una instancia de FormData
        var formData = new FormData();
        formData.append("file", this.file);

        // Realiza la solicitud POST utilizando axios u otra biblioteca
        this.$axiosFile
          .post("/storage/payment/upload", formData, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            resolve(response.data.content);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Carga las especialidades a ser mostradas en el paso 1
    async loadSpecialties() {
      await this.$axios
        .get("/enrollment-speciality/my", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.specialties = response.data.allItems;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    // Carga los pagos pendientes de pago
    async loadPayments() {
      await this.$axios
        .get(
          "/enrollment-speciality/" +
            this.selectedSpecialty +
            "/payment-reason",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.payments = response.data;
          this.calculatePayments();
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    // Muestra la imagen en pantalla
    previewImage(file) {
      // Obtiene el valor de la imagen para enviarla
      this.file = file;

      // Mostrar imagen en formulario
      if (this.selectedImage) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewURL = e.target.result;
        };
        reader.readAsDataURL(this.selectedImage);
      }
    },
    // Calcula automaticamente los pagos
    calculatePayments() {
      // Monto auxiliar
      let amountAux = this.amount;

      // Limpia campos de envio para realizar el nuevo calculo
      this.sendData.amount = 0;
      this.sendData.allPayPension = [];
      this.sendData.payEnroll = {};
      this.viewCalculatePayments = [];

      // Validar pago de matricula
      if (this.payments.debtEnroll === null) {
        this.payments.debtEnroll = {
          residue: 0,
        };
      }

      // Inicio del calculo de pago
      if (amountAux < 0) this.$toast.error("Error de monto");
      else {
        //EJECUTA EL PAGO DE MATRICULA
        // Monto
        this.sendData.amount = amountAux;
        // Pago matricula
        if (amountAux <= this.payments.debtEnroll.residue) {
          //pago en envio
          this.sendData.payEnroll.amount = amountAux;
          // Validar matricula
          if (this.payments.debtEnroll.residue != 0) {
            //mensaje
            this.viewCalculatePayments.push({
              field:
                "Matrícula: Esta pagando S/" +
                this.sendData.payEnroll.amount +
                " --> Debe aun " +
                (this.payments.debtEnroll.residue - amountAux),
            });
          }
        } else {
          //pago en envio
          this.sendData.payEnroll.amount = this.payments.debtEnroll.residue;
          //reduccion de monto
          amountAux = amountAux - this.payments.debtEnroll.residue;
          //mensaje
          // Validar matricula
          if (this.payments.debtEnroll.residue != 0) {
            this.viewCalculatePayments.push({
              field:
                "Matrícula: Esta pagando S/" + this.sendData.payEnroll.amount,
            });
          }
          // Ordenar arreglo de pagos
          this.payments.allDebtPension.sort(
            (a, b) => a.reference - b.reference
          );

          // Recorre las pensiones
          for (let i = 0; i < this.payments.allDebtPension.length; i++) {
            if (amountAux == 0) break;
            else {
              if (amountAux <= this.payments.allDebtPension[i].residue) {
                // ingresa pago de pension al arreglo de envio
                this.sendData.allPayPension.push({
                  amount: amountAux,
                  paymentScheduleId: this.payments.allDebtPension[i].id,
                });
                //mensaje
                this.viewCalculatePayments.push({
                  field:
                    "Pensión " +
                    this.payments.allDebtPension[i].reference +
                    ": Esta pagando S/" +
                    amountAux +
                    " --> Debe aun S/" +
                    (this.payments.allDebtPension[i].residue - amountAux),
                });
                // pone el monto a cero ya que no hay mas saldo
                this.amountAux = 0;
                break;
              } else {
                // ingresa pago de pension al arreglo de envio
                this.sendData.allPayPension.push({
                  amount: this.payments.allDebtPension[i].residue,
                  paymentScheduleId: this.payments.allDebtPension[i].id,
                });
                // pone el monto a cero ya que no hay mas saldo
                amountAux = amountAux - this.payments.allDebtPension[i].residue;
                //mensaje
                this.viewCalculatePayments.push({
                  field:
                    "Pensión " +
                    this.payments.allDebtPension[i].reference +
                    ": Esta pagando S/" +
                    this.payments.allDebtPension[i].residue,
                });
              }
            }
          }
        }
      }
    },
    // Envia la informacion del pago
    sendDataPayment(urlImage) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            "/requisition-pay",
            {
              allPayPension: this.sendData.allPayPension,
              amount: this.sendData.amount,
              coment: this.comment,
              date: this.date,
              enrollmentSpecialityId: this.selectedSpecialty,
              financialEntityId: this.finalcialId,
              operationNumber: this.operationNumber,
              payEnroll: this.sendData.payEnroll,
              urlVoucher: urlImage,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.deleteFilePayment(urlImage);
            this.$toast.error(error.response.data.content);
            reject(error);
          });
      });
    },
    // Elimina archivo del servidor de archivos
    async deleteFilePayment(urlImageGenerate) {
      await this.$axiosFile
        .delete("/storage/payment/remove/" + urlImageGenerate, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    // Envia el pago completo, sube imagen e informacion de pago
    sendPayment() {
      this.uploadFile()
        .then((valor) => {
          return this.sendDataPayment(valor);
        })
        .then(() => {
          this.$toast.success("Pago realizado satisfactoriamente");
          router.push({ name: "myPayments" });
        })
        .catch(() => {
          this.flag = 1;
          this.$toast.error("Ha ocurrido un error al subir la información");
        });
    },
  },
};
</script>