import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from "axios"
import VueToast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import loader from "vue-ui-preloader";

// Uso de la pantalla de carga
Vue.use(loader);

// Importa el archivo CSS de estilos globales
import './styles/global-styles.scss';

Vue.config.productionTip = false

// Uso de notificador
Vue.use(VueToast);

// Enlace principal BACK
Vue.prototype.$axios = axios.create({
  //baseURL: 'https://usefc.site/backend/controller/',
  baseURL: 'https://usefcfips.unsa.edu.pe/backend/controller/',
});

// Enlace principal GESTOR ARCHIVOS
Vue.prototype.$axiosFile = axios.create({
  //baseURL: 'https://usefc.site/backend/file/',
  baseURL: 'https://usefcfips.unsa.edu.pe/backend/file/',
});

// Enlace principal VISOR ARCHIVOS
//Vue.prototype.$axiosMinio = 'https://usefc.site/bucket/payment/';
Vue.prototype.$axiosMinio = 'https://usefcfips.unsa.edu.pe/bucket/payment/';


// Funcion para definir error al realizar consultas
Vue.prototype.$queryError = function (message) {
  if (typeof message.response.data.status === 'undefined') {
    this.$toast.error(message.response.data.content);
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('authenticated');
    localStorage.removeItem('key');
    localStorage.removeItem('information');
    router.push({ name: "errorMessage" });
  }
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
