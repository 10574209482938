<template>
  <v-container class="max-width-container" fluid>
    <v-card class="rounded-lg">
      <v-card-title class="display-1 primary--text"
        ><b>FORMULARIO DE PAGO</b><v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <PaymentStepper />
      </v-card-text>
    </v-card>
  </v-container>
</template>
      <script>
import PaymentStepper from "./Payment_Stepper.vue";
export default {
  name: "PaymentMain",
  components: {
    PaymentStepper,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>