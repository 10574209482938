<template>
  <v-dialog v-model="dialogView" persistent max-width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        color="success"
        @click="getPayments()"
        :disabled="dataPayment.allPayReference.length == 0"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon> mdi-eye-outline </v-icon>
      </v-btn>
    </template>
    <v-card v-if="payment != null">
      <v-card-title>
        {{ "Información de la " + payment.order }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="dataPayment.allPayReference"
            :items-per-page="dataPayment.allPayReference.length"
            class="elevation-1 mt-3 rounded-lg"
            hide-default-footer
          >
            <template slot="item.index" slot-scope="{ item }">
              <b>{{ getIndex(item) }}</b>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="dialogView = false">
          <b>Aceptar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
        
        <script>
export default {
  name: "StudentsPaymentView",
  props: ["dataPayment"],
  data() {
    return {
      dialogView: false,
      payment: null,
      headers: [
        { text: "#", value: "index" },
        { text: "#Operación", value: "operationNumber" },
        { text: "Monto", value: "amount" },
      ],
    };
  },
  created() {},
  methods: {
    getPayments() {
      this.payment = this.dataPayment;
    },
    getIndex(item) {
      const index = this.payment.allPayReference.indexOf(item);
      return index + 1;
    },
  },
};
</script>
    