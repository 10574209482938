<template>
  <v-container class="max-width-container" fluid>
    <v-card class="rounded-lg">
      <v-card-title class="display-1 primary--text"
        ><b>ESTUDIANTES</b><v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-card>
          <v-container>
            <p><b>Filtro para la tabla de estudiantes:</b></p>
            <v-row class="mb-0">
              <v-col cols="12" lg="5" md="5" xs="12">
                <v-select
                  v-model="selectedCycle"
                  :items="dataCycle"
                  item-text="name"
                  item-value="value"
                  label="Ciclo:"
                  prepend-icon="mdi-archive"
                  @change="getDataSpecialty()"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" lg="5" md="5" xs="12">
                <v-select
                  v-model="selectedSpecialty"
                  :items="dataSpecialty"
                  item-text="name"
                  item-value="value"
                  label="Especialidad:"
                  prepend-icon="mdi-archive"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" lg="2" md="2" xs="12">
                <v-btn
                  color="primary"
                  @click="$refs.tableData.loadTable()"
                  block
                  ><b>Filtrar</b></v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <TableStudents
          ref="tableData"
          :idCycle="selectedCycle"
          :idSpecialty="selectedSpecialty"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>
    <script>
import TableStudents from "./Students_TableStudents.vue";
export default {
  name: "StudentsMain",
  components: {
    TableStudents,
  },
  data() {
    return {
      selectedCycle: "",
      selectedSpecialty: "",
      disabledSpecialty: true,
      dataCycle: [],
      dataSpecialty: [],
    };
  },
  created() {
    this.getDataCycle();
  },
  methods: {
    async getDataCycle() {
      // Limpia los datos antes de obtenerlos
      this.dataCycle = [];

      // Obtiene los datos y formatea la informacion para ponerla en la lista de ciclos
      await this.$axios
        .get("/academic-cycle", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.allItems.length; i++) {
            const row = {
              name:
                response.data.allItems[i].year +
                " - " +
                response.data.allItems[i].name,
              value: response.data.allItems[i].id,
            };
            this.dataCycle.push(row);
          }
        })
        .catch((error) => {
          this.$queryError(error);
          console.log(error);
        });
    },

    async getDataSpecialty() {
      // Limpiar tabla de datos
      this.$refs.tableData.clearTable();

      // Limpiam el campo de especialidad seleccionada
      this.selectedSpecialty = "";

      // Limpia los datos antes de obtenerlos
      this.dataSpecialty = [];

      // Obtiene los datos y formatea la informacion para ponerla en la lista de ciclos
      await this.$axios
        .get("/academic-cycle/" + this.selectedCycle + "/speciality", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          // Formatea la informacion para usarla en la lista
          for (let i = 0; i < response.data.allItems.length; i++) {
            const row = {
              name: response.data.allItems[i].name,
              value: response.data.allItems[i].id,
            };
            this.dataSpecialty.push(row);
          }

          // Activa el desplegable de especialidades
          this.disabledSpecialty = false;
        })
        .catch((error) => {
          this.$queryError(error);
          console.log(error);
        });
    },
  },
};
</script>