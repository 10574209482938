<template>
  <v-dialog v-model="dialogEdit" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs">
        <v-icon>mdi-image-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Editar Voucher</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container>
          <cropper
            class="cropper"
            ref="cropper"
            :src="img + `?t=${Date.now()}`"
          />
          <v-bottom-navigation dark>
            <v-btn @click="rotate(-90)">
              <v-icon>mdi-rotate-left</v-icon>
            </v-btn>

            <v-btn @click="rotate(90)">
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogEdit = false">
          <b>Cancelar</b>
        </v-btn>
        <v-btn color="success" text @click="updateFile()"
          ><b>Actualizar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
    <script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  name: "SendPaymentsImage",
  props: ["imageSrc"],

  components: {
    Cropper,
  },
  data() {
    return {
      dialogEdit: false,
      img: this.$axiosMinio + this.imageSrc,
    };
  },
  created() {},
  methods: {
    // Rota la imagen de Voucher
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    // Actualiza la imagen de Voucher
    updateFile() {
      // Obtiene el canvas de la imagen modificada
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const formData = new FormData();
        // Convierte a blob
        canvas.toBlob((blob) => {
          formData.append("file", blob);
          // Realiza la solicitud POST utilizando axios u otra biblioteca
          this.$axiosFile
            .post("/storage/payment/update/" + this.imageSrc, formData, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              // Ejecuta la funcion padre
              this.$emit("loadPayment");
              this.dialogEdit = false;
              this.$toast.success(response.data.content);
            })
            .catch((error) => {
              this.$queryError(error);
            });
        }, "image/jpeg");
      }
    },
  },
};
</script>