<template>
  <div>
    <v-alert v-if="amount != 0" class="mt-4" type="success" text dense
      >{{ "Monto Total: " }}<b>{{ "S/. " + amount }}</b></v-alert
    >
    <v-alert v-else class="mt-4" type="info" text dense
      ><b>{{ "No hay datos en el rango de fechas" }}</b></v-alert
    >
    <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1 rounded-lg"
    >
      <template slot="item.index" slot-scope="{ item }">
        <b>{{ getIndex(item) }}</b>
      </template>
      <template slot="item.amount" slot-scope="{ item }">
        <v-chip color="success">
          <v-icon left> mdi-cash </v-icon><b>{{ "S/. " + item.amount }}</b>
        </v-chip>
      </template>
      <template slot="item.date" slot-scope="{ item }">
        <v-chip color="info" outlined>
          <v-icon left> mdi-calendar-range </v-icon
          >{{ item.registrationMoment.substring(0, 10) }}
        </v-chip>
      </template>
      <template slot="item.hour" slot-scope="{ item }">
        <v-chip color="blue-grey darken-2" outlined>
          <v-icon left> mdi-timer-outline </v-icon>
          {{ item.registrationMoment.substring(11, 19) }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>
      
      <script>
export default {
  name: "TableStudents",
  props: ["startDate", "endDate"],
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Monto", value: "amount" },
        { text: "Fecha de Validación", value: "date" },
        { text: "Hora de Validación", value: "hour" },
      ],
      desserts: [],
      amount: 0,
    };
  },
  created() {
    this.loadTable();
    this.getTotal();
  },
  methods: {
    async loadTable() {
      await this.$axios
        .get(
          "/financial-income/list?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate +
            "&perPage=1000",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.desserts = response.data.allItems;
        })
        .catch((error) => {
          this.desserts = [];
          this.$queryError(error);
        });
    },
    async getTotal() {
      await this.$axios
        .get(
          "/financial-income/total?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate +
            "&perPage=1000",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.amount = response.data.content;
        })
        .catch(() => {
          this.amount = 0;
        });
    },
    getIndex(item) {
      // Obtener el índice del elemento en la lista
      const index = this.desserts.indexOf(item);
      // Agregar 1 al índice para obtener el número correspondiente
      return index + 1;
    },
  },
};
</script>