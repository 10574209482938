<template>
  <div>
    <loader
      v-if="loader"
      object="white"
      size="10"
      speed="1"
      bg="#343a40"
      opacity="30"
      name="dots"
      ref="loader"
    ></loader>
    <v-container class="max-width-container" fluid>
      <v-card class="rounded-lg">
        <v-card-title class="display-1 primary--text"
          ><b>REVISIÓN DE PAGO</b><v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container v-if="dataPayment.specialityOpening != null">
            <v-alert border="left" color="primary" text
              ><b class="text-left"
                >{{ dataPayment.specialityOpening.year
                }}<v-icon color="primary">mdi-arrow-right-thin</v-icon>
                {{ " CICLO " + dataPayment.specialityOpening.cycleName
                }}<v-icon color="primary">mdi-arrow-right-thin</v-icon
                >{{ dataPayment.specialityOpening.specialityName }}</b
              ></v-alert
            >
            <v-row>
              <v-col cols="12" lg="6" md="6">
                <h2>Imagen de Voucher</h2>
                <sendPaymentsImage
                  :imageSrc="dataPayment.urlVoucher"
                  @loadPayment="loadPayment()"
                />
                <v-img
                  v-if="dataPayment.urlVoucher != null"
                  class="zoom-image"
                  @mouseenter="isZoomed = true"
                  @mouseleave="isZoomed = false"
                  :src="
                    $axiosMinio + dataPayment.urlVoucher + `?t=${Date.now()}`
                  "
                  height="450"
                  contain
                ></v-img>
              </v-col>
              <v-col cols="12" lg="6" md="6">
                <v-form>
                  <h2>Información del Voucher</h2>
                  <sendPaymentsInformation
                    :dataPayment="dataPayment"
                    @loadPayment="loadPayment()"
                  ></sendPaymentsInformation>
                  <v-alert
                    v-if="dataPayment.person != null"
                    class="mt-5"
                    border="left"
                    color="info"
                    text
                    ><v-icon color="info">mdi-account</v-icon
                    >{{
                      dataPayment.person.lastnameFather +
                      " " +
                      dataPayment.person.lastnameMother +
                      " " +
                      dataPayment.person.name +
                      " / " +
                      dataPayment.person.email
                    }}</v-alert
                  >
                  <v-text-field
                    v-model="dataPayment.date"
                    label="Fecha de pago"
                    type="date"
                    :readonly="stateReadOnly"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="dataPayment.financialEntity.name"
                    label="Banco"
                    type="text"
                    :readonly="stateReadOnly"
                  ></v-text-field>
                  <v-text-field
                    v-model="dataPayment.operatioNumber"
                    label="Número de operación"
                    type="text"
                    :readonly="stateReadOnly"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="dataPayment.amount"
                    label="Monto de Voucher"
                    type="text"
                    :readonly="stateReadOnly"
                    required
                  ></v-text-field>
                  <h2 class="mb-3">Conceptos de Pago</h2>
                  <v-chip-group active-class="primary--text" column>
                    <v-chip
                      color="success"
                      v-if="
                        dataPayment.payEnroll != null &&
                        dataPayment.payEnroll.amount != 0
                      "
                      outlined
                      ><b
                        ><v-icon left> mdi-cash-multiple </v-icon>
                        {{
                          "Pago de Matrícula S/" + dataPayment.payEnroll.amount
                        }}</b
                      >
                    </v-chip>
                    <div v-if="dataPayment.allPayPension != null">
                      <v-chip
                        color="info"
                        v-for="(tag, index) in dataPayment.allPayPension"
                        :key="index"
                        outlined
                        ><b
                          ><v-icon left> mdi-cash-multiple </v-icon>
                          {{
                            "Pago de Pensión " +
                            tag.reference +
                            " S/" +
                            tag.amount
                          }}</b
                        >
                      </v-chip>
                    </div>
                  </v-chip-group>
                </v-form>
              </v-col>
            </v-row>
            <div v-if="dataPayment.allRemark != null">
              <div v-if="dataPayment.allRemark.length != 0">
                <h2 class="mt-3">Mensajes</h2>
                <v-list-item
                  v-for="(item, index) in dataPayment.allRemark"
                  :key="index"
                  two-line
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.content }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>{{
                    item.moment.substring(0, 10) +
                    " / " +
                    item.moment.substring(11, 19)
                  }}</v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </div>
              <div v-else><h3>Sin mensajes</h3></div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="goToSendPayments()">
            <b>Volver</b>
          </v-btn>
          <!-- Dialogo rechazo de solicitud de pago -->
          <v-dialog v-model="dialogDecline" persistent max-width="550">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" class="ml-2" v-bind="attrs" v-on="on">
                <v-icon left dark> mdi-alert </v-icon>
                <b>Rechazar</b>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                ¿Esta seguro que desea rechazar este pago?
              </v-card-title>
              <v-card-text
                ><p>Esta acción es definitiva y no se podrá deshacer.</p>
                <v-text-field
                  v-model="messageDecline"
                  label="Mensaje"
                  type="text"
                  outlined
                  clearable
                  hide-details
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="dialogDecline = false">
                  <b>Cancelar</b>
                </v-btn>
                <v-btn
                  color="success"
                  text
                  :disabled="messageDecline == '' || messageDecline == null"
                  @click="declinePayment()"
                >
                  <b>Aceptar</b>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!---->
          <!-- Dialogo pregunta de validacion -->
          <v-dialog v-model="dialogValidate" persistent max-width="550">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" class="ml-2" v-bind="attrs" v-on="on">
                <b>Validar</b>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                ¿Esta seguro de validar el pago?
              </v-card-title>
              <v-card-text
                >La información sera valida automáticamente.</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="dialogValidate = false">
                  <b>Cancelar</b>
                </v-btn>
                <v-btn color="success" text @click="validatePayment()">
                  <b>Aceptar</b>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!---->
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>
          <script>
import router from "@/router";
import sendPaymentsImage from "./SentPayments_Image.vue";
import sendPaymentsInformation from "./SentPayments_Information.vue";
export default {
  name: "SentPaymentsValidate",
  components: {
    sendPaymentsImage,
    sendPaymentsInformation,
  },
  data() {
    return {
      dataPayment: {},
      stateReadOnly: true,
      dialogValidate: false,
      dialogDecline: false,
      messageDecline: "",
      loader: false,
      isZoomed: false,
    };
  },
  created() {
    this.loadPayment();
  },
  methods: {
    async loadPayment() {
      await this.$axios
        .get("/requisition-pay/" + this.$route.params.idPayment + "/sent", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.dataPayment = response.data;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    goToSendPayments() {
      router.push({ name: "sentPayments" });
    },
    async declinePayment() {
      this.loader = true;
      await this.$axios
        .put(
          "/requisition-pay/" +
            this.$route.params.idPayment +
            "/refuse?emailNotification=" +
            this.dataPayment.person.email,
          { coment: this.messageDecline },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.$toast.success(response.data.content);
          router.push({ name: "sentPayments" });
        })
        .catch((error) => {
          this.dialogDecline = false;
          this.$queryError(error);
        });
    },
    async validatePayment() {
      this.loader = true;
      await this.$axios
        .put(
          "/requisition-pay/" +
            this.$route.params.idPayment +
            "/validate?emailNotification=" +
            this.dataPayment.person.email,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then(() => {
          this.$toast.success("Se ha validado correctamente el pago");
          router.push({ name: "sentPayments" });
        })
        .catch((error) => {
          this.dialogValidate = false;
          this.$queryError(error);
        });
    },
  },
};
</script>
<style>
.zoom-image {
  transition: transform 0.3s;
}

.zoom-image:hover {
  transform: scale(1.8); /* Ajusta el valor para controlar el nivel de zoom */
}
</style>