<template>
  <v-dialog v-model="dialogDelete" persistent max-width="550">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2" fab x-small color="error" v-bind="attrs" v-on="on">
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        ¿Esta seguro de eliminar a {{ specialtyRow.name }}?
      </v-card-title>
      <v-card-text>La información sera eliminada permanentemente.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogDelete = false">
          <b>Cancelar</b>
        </v-btn>
        <v-btn color="success" text @click="deleteDataRow()">
          <b>Aceptar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
        
        <script>
export default {
  name: "SpecialtyTableDelete",
  props: ["specialtyRow"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  created() {},
  methods: {
    async deleteDataRow() {
      await this.$axios
        .delete(
          "/academic-cycle/" +
            this.$route.params.idCycle +
            "/speciality/" +
            this.specialtyRow.id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.dialogDelete = false;
          this.$emit("loadTable");
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
          console.log(error);
        });
    },
  },
};
</script>