<template>
  <v-dialog v-model="dialogEdit" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon @click="getDataPayment()" v-on="on" v-bind="attrs">
        <v-icon>mdi-image-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Editar Datos Vaucher</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container>
          <v-text-field
            v-model="formData.date"
            :rules="empty"
            :error-messages="formData.date ? '' : 'Campo requerido'"
            class="mt-5"
            label="Ingrese Fecha de pago"
            type="date"
            background-color="white"
            outlined
            clearable
            required
          ></v-text-field>
          <v-select
            v-model="formData.financialEntityId"
            :rules="empty"
            :error-messages="
              formData.financialEntityId ? '' : 'Campo requerido'
            "
            :items="entities"
            item-text="text"
            item-value="value"
            label="Seleccione Banco"
            background-color="white"
            outlined
            chips
            small-chips
          ></v-select>
          <v-text-field
            v-model="formData.operationNumber"
            :rules="empty"
            :error-messages="formData.operationNumber ? '' : 'Campo requerido'"
            label="Número de operación"
            type="number"
            background-color="white"
            outlined
            clearable
            required
          ></v-text-field>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogEdit = false">
          <b>Cancelar</b>
        </v-btn>
        <v-btn color="success" text @click="updateDataPayment()"
          ><b>Actualizar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
      
      <script>
export default {
  name: "SendPaymentsInformation",
  props: ["dataPayment"],
  data() {
    return {
      dialogEdit: false,
      entities: [],
      empty: [(v) => !!v || "Campo requerido"],
      formData: {},
    };
  },
  created() {
    this.getEntities();
  },
  methods: {
    // Obtiene las entidades financieras - bancos
    async getEntities() {
      await this.$axios
        .get("/financial-entity", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.entities = response.data;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },

    // Obtiene los datos que seran modificado
    getDataPayment() {
      this.formData = {
        date: this.dataPayment.date,
        financialEntityId: this.dataPayment.financialEntity.id,
        operationNumber: this.dataPayment.operatioNumber,
      };
    },

    // Actualiza la informacion del Voucher
    async updateDataPayment() {
      await this.$axios
        .put("/requisition-pay/" + this.dataPayment.id, this.formData, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          // Ejecuta la funcion padre
          this.$emit("loadPayment");
          this.dialogEdit = false;
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
  },
};
</script>