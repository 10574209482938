<template>
  <v-container class="max-width-container" fluid>
    <v-breadcrumbs :items="itemsRouter" large></v-breadcrumbs>
    <v-card class="rounded-lg">
      <v-card-title class="display-1 primary--text"
        ><b>TABLA DE CICLOS</b><v-spacer></v-spacer>
        <tableAdd @loadTable="loadTable()"></tableAdd>
      </v-card-title>
      <v-card-text>
        <TableCycle ref="tableData" />
      </v-card-text>
    </v-card>
  </v-container>
</template>
  <script>
import TableCycle from "./Cycle_TableCycle.vue";
import tableAdd from "./Cycle_TableAdd.vue";
export default {
  name: "CycleMain",
  components: {
    TableCycle,
    tableAdd,
  },
  data() {
    return {
      itemsRouter: [
        {
          text: "Ciclo",
          disabled: true,
        },
        {
          text: "",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    loadTable() {
      this.$refs.tableData.loadTable();
    },
  },
};
</script>