<template>
  <div>
    <loader
      v-if="loader"
      object="white"
      size="10"
      speed="1"
      bg="#343a40"
      opacity="30"
      name="dots"
      ref="loader"
    ></loader>
    <div v-if="items.length != 0">
      <v-timeline dense>
        <v-slide-x-reverse-transition group hide-on-leave>
          <v-timeline-item
            v-for="item in items"
            :key="item.id"
            :color="item.status.color"
            small
            fill-dot
          >
            <v-alert
              :value="true"
              :color="item.status.color"
              class="white--text"
            >
              <h3>
                {{
                  "Pago " +
                  item.status.name +
                  " - " +
                  item.specialityOpening.specialityName
                }}
              </h3>
              <MyPaymentsInformation :paymentId="item.id" />
              <v-divider class="my-1"></v-divider>
              <h4>Fecha y Hora: {{ convertDate(item.moment) }}</h4>
              <h4>Monto: S/{{ item.amount }}</h4>
            </v-alert>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
    </div>
    <div v-else>
      <v-alert dense text type="info"> No hay pagos. </v-alert>
    </div>
  </div>
</template>
      
      <script>
import MyPaymentsInformation from "./MyPayments_Information.vue";
export default {
  name: "MyPaymentsTable",
  components: {
    MyPaymentsInformation,
  },
  data() {
    return {
      loader: false,
      interval: null,
      items: [],
      nonce: 2,
    };
  },
  created() {
    this.loadRequisitionPay();
  },
  methods: {
    async loadRequisitionPay() {
      this.loader = true;
      await this.$axios
        .get("/requisition-pay/my?perPage=100", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.items = response.data.allItems;
          this.loader = false;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    convertDate(value) {
      let date = value.substring(0, 10);
      let hour = value.substring(11, 19);

      return date + " " + hour;
    },
  },
};
</script>