<template>
  <v-dialog v-model="dialogAdd" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        @click="startDialog()"
        rounded
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left> mdi-pencil-plus </v-icon>
        <b>Agregar</b>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="success">
        <span class="text-h5 white--text">Agregar Especialidad</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container>
          <MessageError ref="messageError" />
          <v-form>
            <!-- Dialogo de fecha de inicio -->
            <v-dialog
              ref="dialogStart"
              v-model="modalStart"
              :return-value.sync="formData.data.startDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.data.startDate"
                  label="Fecha de inicio:"
                  prepend-icon="mdi-calendar"
                  :rules="empty"
                  readonly
                  outlined
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formData.data.startDate"
                color="primary"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalStart = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogStart.save(formData.data.startDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- Dialogo de fecha de fin -->
            <v-dialog
              ref="dialogFinal"
              v-model="modalFinal"
              :return-value.sync="formData.data.finalDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.data.finalDate"
                  label="Fecha de fin:"
                  prepend-icon="mdi-calendar"
                  :rules="empty"
                  readonly
                  outlined
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formData.data.finalDate"
                color="primary"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFinal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogFinal.save(formData.data.finalDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- Campos de monto ede matricula y pension -->
            <v-text-field
              v-model="formData.data.enrollCost"
              label="Costo de Matrícula"
              prepend-icon="mdi-cash-multiple"
              type="number"
              :rules="empty"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.data.pensionCost"
              label="Costo de Pensión"
              prepend-icon="mdi-cash-multiple"
              type="number"
              :rules="empty"
              outlined
              clearable
              required
            ></v-text-field>
            <!-- Muestra de Checkbox especialidades -->
            <h2 v-if="itemsCheckbox.length != 0">Especialidades:</h2>
            <h2 class="text-center" v-else>
              No hay especialidades disponibles
            </h2>
            <v-checkbox
              v-for="(item, index) in itemsCheckbox"
              :key="index"
              v-model="formData.allSpecialityId"
              :label="item.text"
              :value="item.value"
              hide-details
            ></v-checkbox>
            <!---->
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogAdd = false">
          <b>Cerrar</b>
        </v-btn>
        <v-btn color="success" text @click="addSpecialty()"
          ><b>Enviar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
      
      <script>
import MessageError from "@/components/Message_Error.vue";
export default {
  name: "SpecialtyTableAdd",
  components: {
    MessageError,
  },
  data() {
    return {
      modalStart: false,
      modalFinal: false,
      dialogAdd: false,
      itemsCheckbox: [],
      empty: [(val) => (val || "").length > 0 || "Este campo es requerido"],
      formData: {
        allSpecialityId: [],
        data: {
          finalDate: "",
          startDate: "",
          enrollCost: 0,
          pensionCost: 0,
        },
      },
    };
  },
  created() {},
  methods: {
    async getItemsList() {
      await this.$axios
        .get(
          "/academic-cycle/" + this.$route.params.idCycle + "/not-speciality",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.itemsCheckbox = response.data;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    async addSpecialty() {
      await this.$axios
        .post(
          "/academic-cycle/" +
            this.$route.params.idCycle +
            "/speciality/multiple-register",
          this.formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.dialogAdd = false;
          this.$emit("loadTable");
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
          this.$refs.messageError.showAlertError(error.response.data.content);
        });
    },
    startDialog() {
      // Obtiene lista de datos para el checkbox
      this.getItemsList();
      // Limpia la información para hacer la nueva selección
      (this.formData = {
        allSpecialityId: [],
        data: {
          finalDate: "",
          startDate: "",
          enrollCost: 0,
          pensionCost: 0,
        },
      }),
        (this.itemsCheckbox = []),
        // Verifica que se pueda hacer uso del componente mensaje error
        this.$nextTick(() => {
          if (this.$refs.messageError) {
            this.$refs.messageError.hideAlertError();
          }
        });
    },
  },
};
</script>