<template>
  <div>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center">
          <h1 class="display-1">Error de autenticación</h1>
          <p>Lo sentimos, no tienes permiso para acceder a esta página.</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import router from "@/router";
export default {
  name: "ErrorMessage",
  data() {
    return {};
  },
  created() {
    setTimeout(() => {
      this.routerLogin();
    }, 3500);
  },
  methods: {
    routerLogin() {
      router.push({ name: "login" });
    },
  },
};
</script>
<style>
.text-center {
  text-align: center;
}
</style>