<template>
  <v-dialog v-model="dialogEdit" persistent max-width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        color="warning"
        @click="getAmount()"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ "¿Esta seguro que desea modificar la " + namePayment + "?" }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="amountPayment"
          label="Monto"
          type="number"
          outlined
          clearable
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogEdit = false">
          <b>Cancelar</b>
        </v-btn>
        <v-btn
          color="success"
          text
          :disabled="amountPayment == 0 || amountPayment == null"
          @click="updateCost()"
        >
          <b>Aceptar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
      
      <script>
export default {
  name: "StudentsPaymentEdit",
  props: ["dataRow"],
  data() {
    return {
      dialogEdit: false,
      amountPayment: 0,
      namePayment: "",
    };
  },
  created() {
  },
  methods: {
    getAmount() {
      this.amountPayment = this.dataRow.cost;
      this.namePayment = this.dataRow.order;
    },

    async updateCost() {
      if (this.dataRow.order == "Matrícula")
        this.updatePaymentEnrollment(this.dataRow.id);
      else this.updatePaymentPension(this.dataRow.id);
    },

    // Actualiza monto de matricula
    async updatePaymentEnrollment(id) {
      await this.$axios
        .patch(
          "/enrollment-speciality/" + id + "/modify/cost",
          {
            value: this.amountPayment,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.dialogEdit = false;
          this.$emit("loadPaymentDetail");
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },

    // Actualiza monto de pension
    async updatePaymentPension(id) {
      await this.$axios
        .patch(
          "/payment-schedule/" + id + "/modify/cost",
          {
            value: this.amountPayment,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.dialogEdit = false;
          this.$emit("loadPaymentDetail");
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
  },
};
</script>
  