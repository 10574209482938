<template>
  <v-container class="max-width-container" fluid>
    <v-card class="rounded-lg">
      <v-card-title class="display-1 primary--text"
        ><b>FINANZAS</b><v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-card>
          <v-container>
            <p><b>Filtro de fechas para movimientos financieros</b></p>
            <v-row class="mb-0">
              <v-col cols="12" lg="5" md="5" xs="12">
                <v-text-field
                  v-model="startDate"
                  label="Fecha Inicio"
                  type="date"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="5" md="5" xs="12">
                <v-text-field
                  v-model="endDate"
                  :disabled="startDate == ''"
                  label="Fecha Fin"
                  type="date"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="2" md="2" xs="12">
                <v-btn
                  color="primary"
                  @click="
                    $refs.financeTable.loadTable();
                    $refs.financeTable.getTotal();
                  "
                  block
                  ><b>Filtrar</b></v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <FinanceTable
          ref="financeTable"
          :startDate="startDate"
          :endDate="endDate"
        />
        <ExportExcel />
      </v-card-text>
    </v-card>
  </v-container>
</template>
      <script>
import FinanceTable from "./Finance_Table.vue";
import ExportExcel from "./Finance_ExportCsvPdf.vue";
export default {
  name: "FinanceMain",
  components: { FinanceTable, ExportExcel },
  data() {
    return {
      startDate: "",
      endDate: "",
    };
  },
  created() {},
  methods: {},
};
</script>