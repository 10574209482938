<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1 mt-3 rounded-lg"
    >
      <template slot="item.index" slot-scope="{ item }">
        <b>{{ getIndex(item) }}</b>
      </template>
      <template slot="item.lastName" slot-scope="{ item }">
        {{
          item.person.lastnameFather +
          " " +
          item.person.lastnameMother +
          ", " +
          item.person.name
        }}
      </template>
      <template slot="item.date" slot-scope="{ item }">
        <v-chip color="info" outlined>
          <v-icon left> mdi-calendar-range </v-icon
          >{{ item.moment.substring(0, 10) }}
        </v-chip>
      </template>
      <template slot="item.hour" slot-scope="{ item }">
        <v-chip color="blue-grey darken-2" outlined>
          <v-icon left> mdi-timer-outline </v-icon>
          {{ item.moment.substring(11, 19) }}
        </v-chip>
      </template>
      <template slot="item.view" slot-scope="{ item }">
        <v-btn color="success" @click="goToValidate(item.id)" fab x-small>
          <v-icon>mdi-eye-outline </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
    
    <script>
import router from "@/router";
export default {
  name: "SentPaymentsTable",
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Nombres", value: "lastName" },
        { text: "Especialidad", value: "specialityOpening.specialityName" },
        { text: "Fecha", value: "date" },
        { text: "Hora", value: "hour" },
        { text: "Ver", value: "view" },
      ],
      desserts: [],
    };
  },
  created() {
    this.loadTable();
  },
  methods: {
    async loadTable() {
      await this.$axios
        .get("/requisition-pay/sent?perPage=1000", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.desserts = response.data.allItems;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    getIndex(item) {
      // Obtener el índice del elemento en la lista
      const index = this.desserts.indexOf(item);
      // Agregar 1 al índice para obtener el número correspondiente
      return index + 1;
    },
    goToValidate(idPayment) {
      router.push({
        name: "validate",
        params: {
          idPayment: idPayment,
        },
      });
    },
  },
};
</script>