<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1 mt-3 rounded-lg"
    >
      <template slot="item.index" slot-scope="{ item }">
        <b>{{ getIndex(item) }}</b>
      </template>
      <template slot="item.state" slot-scope="{ item }">
        <v-chip color="success" dark>
          <b>{{ (item.state = "A" ? "ACTIVO" : "") }}</b>
        </v-chip>
      </template>
      <template slot="item.specialty" slot-scope="{ item }">
        <v-chip
          color="info"
          @click="goToSpecialty(item.year, item.name, item.id)"
          link
          outlined
        >
          <v-icon left> mdi-server-plus </v-icon>
          <b>Especialidades</b>
        </v-chip>
      </template>
      <template slot="item.actions" slot-scope="{ item }">
        <tableEdit @loadTable="loadTable()" :cycleRow="item"></tableEdit>
        <tableDelete @loadTable="loadTable()" :cycleRow="item"></tableDelete>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import router from "@/router";
import tableEdit from "./Cycle_TableEdit.vue";
import tableDelete from "./Cycle_TableDelete.vue";
export default {
  name: "TableCycle",
  components: {
    tableEdit,
    tableDelete,
  },
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Año", value: "year" },
        { text: "Nombre", value: "name" },
        { text: "Estado", value: "state" },
        { text: "Especialidad", value: "specialty" },
        { text: "Acciones", value: "actions" },
      ],
      desserts: [],
    };
  },
  created() {
    this.loadTable();
  },
  methods: {
    async loadTable() {
      await this.$axios
        .get("/academic-cycle", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.desserts = response.data.allItems;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    getIndex(item) {
      // Obtener el índice del elemento en la lista
      const index = this.desserts.indexOf(item);
      // Agregar 1 al índice para obtener el número correspondiente
      return index + 1;
    },
    goToSpecialty(yearCycle, nameCycle, idCycle) {
      router.push({
        name: "specialty",
        params: {
          yearCycle: yearCycle,
          nameCycle: nameCycle,
          idCycle: idCycle,
        },
      });
    },
  },
};
</script>