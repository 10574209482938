<template>
  <div>
    <v-alert
      v-if="AlertView"
      class="text-left"
      border="left"
      color="red"
      dismissible
      icon="mdi-alert-circle"
      type="success"
      >{{ AlertMessage }}</v-alert
    >
  </div>
</template>
    <script>
export default {
  name: "MessageError",
  data() {
    return {
      AlertView: false,
      AlertMessage: "",
    };
  },
  methods: {
    showAlertError(error) {
      this.AlertMessage = error;
      this.AlertView = true;
    },
    hideAlertError() {
      this.AlertView = false;
    },
  },
};
</script>