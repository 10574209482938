<template>
    <v-container class="max-width-container" fluid>
      <v-card class="rounded-lg">
        <v-card-title class="display-1 primary--text"
          ><b>PAGOS RECIBIDOS</b><v-spacer></v-spacer>
        </v-card-title>
  
        <v-card-text>
          <SentPaymentsTable />
        </v-card-text>
      </v-card>
    </v-container>
  </template>
          <script>
  import SentPaymentsTable from "./SentPayments_Table.vue";
  export default {
    name: "SentPaymentsMain",
    components: {
      SentPaymentsTable,
    },
    data() {
      return {};
    },
    created() {},
    methods: {},
  };
  </script>