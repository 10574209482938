<template>
  <v-dialog v-model="dialogEdit" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        @click="getDataStudent()"
        color="warning"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Editar Estudiante</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container>
          <v-form>
            <v-text-field
              v-model="formData.lastnameFather"
              label="Apellido Paterno"
              type="text"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.lastnameMother"
              label="Apellido Materno"
              type="text"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.name"
              label="Nombres"
              type="text"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.cui"
              label="CUI"
              type="number"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.email"
              label="Correo"
              type="text"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.phone"
              label="Celular"
              type="number"
              outlined
              clearable
              required
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogEdit = false">
          <b>Cancelar</b>
        </v-btn>
        <v-btn color="success" text @click="updateStudent()"
          ><b>Actualizar</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
          
          <script>
export default {
  name: "StudentsTableEdit",
  props: ["dataStudent"],
  data() {
    return {
      dialogEdit: false,
      formData: {
        cui: 0,
        email: "",
        lastnameFather: "",
        lastnameMother: "",
        name: "",
        phone: 0,
      },
    };
  },
  created() {},
  methods: {
    // Obtiene los datos que seran modificados del estudiante
    getDataStudent() {
      this.formData.cui = this.dataStudent.person.cui;
      this.formData.email = this.dataStudent.person.email;
      this.formData.lastnameFather = this.dataStudent.person.lastnameFather;
      this.formData.lastnameMother = this.dataStudent.person.lastnameMother;
      this.formData.name = this.dataStudent.person.name;
      this.formData.phone = this.dataStudent.person.phone;
    },

    // Actualiza los datos
    async updateStudent() {
      await this.$axios
        .put(
          "/person/" + this.dataStudent.person.dni + "/modify-info",
          this.formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          this.dialogEdit = false;
          this.$emit("loadTable");
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
  },
};
</script>