<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1 mt-3 rounded-lg"
    >
      <template slot="item.index" slot-scope="{ item }">
        <b>{{ getIndex(item) }}</b>
      </template>
      <template slot="item.enrollCost" slot-scope="{ item }">
        <b>{{ "S/ " + item.enrollCost }}</b>
      </template>
      <template slot="item.pensionCost" slot-scope="{ item }">
        <b>{{ "S/ " + item.pensionCost }}</b>
      </template>
      <template slot="item.state" slot-scope="{ item }">
        <v-chip color="green" dark>
          <b>{{ item.calories }}ACTIVO</b>
        </v-chip>
      </template>
      <template slot="item.register" slot-scope="{ item }">
        <registerMass :specialtyRow="item" />
      </template>
      <template slot="item.actions" slot-scope="{ item }">
        <tableEdit @loadTable="loadTable()" :specialtyRow="item"></tableEdit>
        <tableDelete
          @loadTable="loadTable()"
          :specialtyRow="item"
        ></tableDelete>
      </template>
    </v-data-table>
  </div>
</template>
  
  <script>
import tableEdit from "./Specialty_TableEdit.vue";
import tableDelete from "./Specialty_TableDelete.vue";
import registerMass from "../File_Register/Register_Mass.vue";
export default {
  name: "TableSpecialty",
  components: {
    tableEdit,
    tableDelete,
    registerMass,
  },
  data() {
    return {
      dialogDelete: false,
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Nombre", value: "name" },
        { text: "#Cursos", value: "numberCourses" },
        { text: "Matrícula", value: "enrollCost" },
        { text: "Pensión", value: "pensionCost" },
        { text: "Estado", value: "state" },
        { text: "Registro", value: "register" },
        { text: "Acciones Especialidad", value: "actions" },
      ],
      desserts: [],
    };
  },
  created() {
    this.loadTable();
  },
  methods: {
    async loadTable() {
      await this.$axios
        .get("/academic-cycle/" + this.$route.params.idCycle + "/speciality", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.desserts = response.data.allItems;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
    getIndex(item) {
      // Obtener el índice del elemento en la lista
      const index = this.desserts.indexOf(item);
      // Agregar 1 al índice para obtener el número correspondiente
      return index + 1;
    },
  },
};
</script>