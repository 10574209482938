var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"LoginMain"}},[_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"5"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-window',[_c('v-window-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card-text',{staticClass:"mt-12"},[_c('h1',{staticClass:"text-center display-1 font-weight-bold primary--text"},[_vm._v("Sistema Financiero - Segunda Especialidad Fips Unsa")]),_c('h2',{staticClass:"text-center headline font-weight-bold mt-2"},[_vm._v("Inicio de Sesión")]),_c('h4',{staticClass:"text-center mb-2"},[_vm._v(" Ingrese su DNI y contraseña ")]),(_vm.AlertView)?_c('v-alert',{staticClass:"text-left",attrs:{"border":"left","color":"error","dismissible":"","icon":"mdi-alert-circle","type":"success"}},[_vm._v(_vm._s(_vm.AlertMessage))]):_vm._e(),_c('v-form',[_c('v-text-field',{attrs:{"label":"Número de DNI","name":"dni","rules":[
                            (v) => !!v || 'Este campo es requerido',
                            (v) =>
                              (v && v.length >= 6) ||
                              'La longitud mínima es de 6 caracteres',
                          ],"prepend-icon":"mdi-account","type":"text","color":"primary","clearable":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('v-text-field',{attrs:{"label":"Contraseña","name":"password","rules":[
                            (v) => !!v || 'Este campo es requerido',
                            (v) =>
                              (v && v.length >= 6) ||
                              'La longitud mínima es de 6 caracteres',
                          ],"prepend-icon":"mdi-lock","color":"primary","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"hint":"Haz clic en el icono del ojo para mostrar u ocultar la contraseña","clearable":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('div',{staticClass:"text-center mb-lg-5"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.login()}}},[_vm._v("INGRESAR")])],1)],1),_c('v-col',{staticClass:"primary",attrs:{"cols":"12","md":"4"}},[_c('v-card-text',{staticClass:"white--text mt-lg-14 mt-md-14"},[_c('h1',{staticClass:"text-center headline font-weight-bold"},[_vm._v("ENLACE")]),_c('h3',{staticClass:"text-center my-2"},[_vm._v("Visita nuestra página WEB")]),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"secondary","href":"https://fips.unsa.edu.pe/usefc/"}},[_vm._v("IR AL SITIO")])],1)])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }