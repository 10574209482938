<template>
  <v-dialog v-model="dialogExport" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mt-3" color="primary" block v-bind="attrs" v-on="on">
        <b>Descarga de Pagos</b>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Descarga Excel</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container>
          <v-form>
            <v-text-field
              v-model="startDate"
              label="Fecha de inicio"
              type="date"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="endDate"
              label="Fecha de fin"
              type="date"
              outlined
              clearable
              required
            ></v-text-field>
            <v-select
              v-model="idCycle"
              :items="listCycles"
              item-text="name"
              item-value="id"
              label="Ciclo"
              @change="getSpecialties()"
              outlined
              chips
              small-chips
            ></v-select>
            <v-select
              v-model="idSpecialty"
              :items="listSpecialties"
              item-text="name"
              item-value="id"
              label="Especialidad"
              :disabled="idCycle == null"
              outlined
              chips
              small-chips
              hide-details
            ></v-select>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogExport = false">
          <b>Cerrar</b>
        </v-btn>
        <v-btn
          color="success"
          text
          @click="exportExcel()"
          :disabled="
            startDate == null ||
            endDate == null ||
            idCycle == null ||
            idSpecialty == null
          "
        >
          <v-icon left> mdi-file-excel </v-icon>
          <b>Excel</b>
        </v-btn>
        <v-btn
          color="info"
          text
          @click="exportPdf()"
          :disabled="
            startDate == null ||
            endDate == null ||
            idCycle == null ||
            idSpecialty == null
          "
        >
          <v-icon left> mdi-file-pdf-box </v-icon>
          <b>Pdf</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
<script>
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
export default {
  name: "FinanceExportExcel",
  data() {
    return {
      dialogExport: false,
      startDate: null,
      endDate: null,
      listCycles: [],
      listSpecialties: [],
      idCycle: null,
      idSpecialty: null,
    };
  },
  created() {
    this.getCycles();
  },
  methods: {
    async getCycles() {
      this.listCycles = [];
      await this.$axios
        .get("/academic-cycle", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.allItems.length; i++) {
            const row = {
              name:
                response.data.allItems[i].year +
                " - " +
                response.data.allItems[i].name,
              id: response.data.allItems[i].id,
            };
            this.listCycles.push(row);
          }
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },

    async getSpecialties() {
      await this.$axios
        .get("/academic-cycle/" + this.idCycle + "/speciality", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.listSpecialties = response.data.allItems;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },

    // Exporta y acomoda la informacion de pagos en un archivo excel
    async exportExcel() {
      await this.$axios
        .get(
          "/report/pays-csv?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate +
            "&academicCicleId=" +
            this.idCycle +
            "&specialityId=" +
            this.idSpecialty,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.length == 0)
            this.$toast.error("No hay datos en el rango de fechas");
          else {
            const wb = utils.book_new();
            const ws = utils.json_to_sheet(response.data);

            ws["A1"].v = "PATERNO";
            ws["B1"].v = "MATERNO";
            ws["C1"].v = "NOMBRES";
            ws["D1"].v = "CUI";
            ws["E1"].v = "DNI";
            ws["F1"].v = "CONCEPTO";
            ws["G1"].v = "MONTO";
            ws["H1"].v = "NUMERO OPERACION";
            ws["I1"].v = "FECHA DE PAGO";
            ws["J1"].v = "CORREO";
            ws["K1"].v = "ESPECIALIDAD";

            utils.book_append_sheet(wb, ws, "Pagos");

            const wbout = write(wb, { bookType: "xlsx", type: "array" });
            const blob = new Blob([wbout], {
              type: "application/octet-stream",
            });
            saveAs(
              blob,
              "Relación de Pagos CSV " +
                this.startDate +
                " al " +
                this.endDate +
                ".xlsx"
            );

            this.dialogExport = false;
          }
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },

    // Exporta y crea el diseño del pdf con la información de los pagos
    async exportPdf() {
      await this.$axios
        .get(
          "/report/pays-pdf?startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate +
            "&academicCicleId=" +
            this.idCycle +
            "&specialityId=" +
            this.idSpecialty,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.length == 0)
            this.$toast.error("No hay datos en el rango de fechas");
          else {
            const data = response.data;

            const doc = new jsPDF({
              orientation: "landscape", // Orientación horizontal
            });

            // Cambiar el tamaño de fuente
            doc.setFontSize(8);

            // Recorrer el arreglo de objetos
            for (let i = 0; i < data.length; i++) {
              if (i !== 0) {
                doc.addPage();
              }
              // Primera imagen
              // Añadir texto
              doc.text(data[i].specialityName, 10, 10);
              doc.text(
                data[i].personLastnameFather +
                  " " +
                  data[i].personLastnameMother +
                  ", " +
                  data[i].personName,
                10,
                20
              );

              // Añadir imagen
              doc.addImage(
                this.$axiosMinio + data[i].requisitionPayUrlVoucher,
                "JPEG",
                10,
                30,
                135,
                170
              );
              // Los parámetros son: imagen, formato, coordenadas x, coordenadas y, ancho, alto

              i++;

              if (i != data.length) {
                // Segunda imagen
                // Añadir texto
                doc.text(data[i].specialityName, 153, 10);
                doc.text(
                  data[i].personLastnameFather +
                    " " +
                    data[i].personLastnameMother +
                    ", " +
                    data[i].personName,
                  153,
                  20
                );

                // Añadir imagen
                doc.addImage(
                  this.$axiosMinio + data[i].requisitionPayUrlVoucher,
                  "JPEG",
                  153,
                  30,
                  135,
                  170
                );
              }
            }

            doc.save(
              "Relación de Pagos PDF " +
                this.startDate +
                " al " +
                this.endDate +
                ".pdf"
            );
          }
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
  },
};
</script>