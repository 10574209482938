<template>
  <v-dialog v-model="dialogAdd" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        @click="startDialog()"
        rounded
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left> mdi-pencil-plus </v-icon>
        <b>Agregar</b>
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="success">
        <span class="text-h5 white--text">Agregar Ciclo</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container>
          <MessageError ref="messageError" />
          <v-form>
            <v-text-field
              v-model="formData.name"
              label="Nombre:"
              type="text"
              :rules="empty"
              outlined
              clearable
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.year"
              label="Año:"
              type="number"
              :rules="empty"
              outlined
              clearable
              required
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogAdd = false">
          <b>Cerrar</b>
        </v-btn>
        <v-btn color="success" text @click="addDataRow()"><b>Enviar</b> </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
    <script>
import MessageError from "@/components/Message_Error.vue";
export default {
  name: "CycleTableAdd",
  components: {
    MessageError,
  },
  data() {
    return {
      dialogAdd: false,
      empty: [(val) => (val || "").length > 0 || "Este campo es requerido"],
      formData: {},
    };
  },
  created() {},
  methods: {
    async addDataRow() {
      await this.$axios
        .post("/academic-cycle/", this.formData, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.dialogAdd = false;
          this.$emit("loadTable");
          this.$toast.success(response.data.content);
        })
        .catch((error) => {
          this.$queryError(error);
          this.$refs.messageError.showAlertError(error.response.data.content);
        });
    },
    startDialog() {
      this.formData = {};
      this.$nextTick(() => {
        if (this.$refs.messageError) {
          this.$refs.messageError.hideAlertError();
        }
      });
    },
  },
};
</script>