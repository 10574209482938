<template>
  <v-dialog v-model="dialogEdit" persistent max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        outlined
        dark
        @click="loadInformationPay()"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon left>mdi-eye-outline</v-icon>
        Ver Pago
      </v-btn>
    </template>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="text-h5 white--text">Información de Voucher</span>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-container v-if="dataPayment.specialityOpening != null">
          <v-alert border="left" color="primary" text
            ><b class="text-left"
              >{{ dataPayment.specialityOpening.year
              }}<v-icon color="primary">mdi-arrow-right-thin</v-icon>
              {{ " CICLO " + dataPayment.specialityOpening.cycleName
              }}<v-icon color="primary">mdi-arrow-right-thin</v-icon
              >{{ dataPayment.specialityOpening.specialityName }}</b
            ></v-alert
          >
          <v-row>
            <v-col cols="12" lg="6" md="6">
              <h2>Imagen de Voucher</h2>
              <v-img
                v-if="dataPayment.urlVoucher != null"
                :src="$axiosMinio + dataPayment.urlVoucher + `?t=${Date.now()}`"
                height="450"
                contain
              ></v-img>
            </v-col>
            <v-col cols="12" lg="6" md="6">
              <v-form>
                <h2>Información del Voucher</h2>
                <v-text-field
                  v-model="dataPayment.date"
                  label="Fecha de pago"
                  type="date"
                  :readonly="stateReadOnly"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="dataPayment.financialEntity.name"
                  label="Banco"
                  type="text"
                  :readonly="stateReadOnly"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="dataPayment.operatioNumber"
                  label="Número de operación"
                  type="text"
                  :readonly="stateReadOnly"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="dataPayment.amount"
                  label="Monto de Voucher"
                  type="text"
                  :readonly="stateReadOnly"
                  required
                ></v-text-field>
                <h2 class="mb-3">Conceptos de Pago</h2>
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                    color="success"
                    v-if="
                      dataPayment.payEnroll != null &&
                      dataPayment.payEnroll.amount != 0
                    "
                    outlined
                    ><b
                      ><v-icon left> mdi-cash-multiple </v-icon>
                      {{
                        "Pago de Matrícula S/" + dataPayment.payEnroll.amount
                      }}</b
                    >
                  </v-chip>
                  <div v-if="dataPayment.allPayPension != null">
                    <v-chip
                      color="info"
                      v-for="(tag, index) in dataPayment.allPayPension"
                      :key="index"
                      outlined
                      ><b
                        ><v-icon left> mdi-cash-multiple </v-icon>
                        {{
                          "Pago de Pensión " +
                          tag.reference +
                          " S/" +
                          tag.amount
                        }}</b
                      >
                    </v-chip>
                  </div>
                </v-chip-group>
              </v-form>
            </v-col>
          </v-row>
          <div v-if="dataPayment.allRemark != null">
            <div v-if="dataPayment.allRemark.length != 0">
              <h2 class="mt-3">Mensajes</h2>
              <v-list-item
                v-for="(item, index) in dataPayment.allRemark"
                :key="index"
                two-line
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.content }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>{{
                  item.moment.substring(0, 10) +
                  " / " +
                  item.moment.substring(11, 19)
                }}</v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </div>
            <div v-else><h3>Sin mensajes</h3></div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="dialogEdit = false"
          ><b>Volver</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
      
      <script>
export default {
  name: "MyPaymentsInformation",
  props: ["paymentId"],
  data() {
    return {
      dialogEdit: false,
      stateReadOnly: true,
      dataPayment: {},
    };
  },
  created() {},
  methods: {
    async loadInformationPay() {
      await this.$axios
        .get("/requisition-pay/" + this.paymentId + "/my", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          this.dataPayment = response.data;
        })
        .catch((error) => {
          this.$queryError(error);
        });
    },
  },
};
</script>