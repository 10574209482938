<template>
  <v-dialog v-model="dialogRegisterMass" persistent max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        color="success"
        @click="startDialog()"
        v-bind="attrs"
        v-on="on"
        link
        outlined
      >
        <v-icon left> mdi-account-plus </v-icon>
        <b>Registro</b>
      </v-chip>
    </template>
    <v-card class="rounded-lg">
      <v-toolbar dark color="primary">
        <v-toolbar-title
          ><span class="text-h5 white--text"
            >Registro Masivo</span
          ></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialogRegisterMass = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-container>
          <v-alert
            class="text-left"
            border="left"
            text
            icon="mdi-alert-circle"
            type="info"
            ><b
              >Puede eliminar filas de datos en el campo de acciones, si hay un
              error se presenta en el primer dato de la tabla.</b
            ></v-alert
          >
          <v-file-input
            label="Archivo Excel"
            @change.native="handleFileUpload"
          ></v-file-input>
          <MessageError ref="messageError" />

          <v-data-table
            :headers="headers"
            :items="formData"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td v-for="(value, key) in item" :key="key">
                  <template v-if="key !== 'id'">
                    <template v-if="key !== 'actions'">
                      <input
                        :size="item[key].toString().length + 1"
                        type="text"
                        v-model="item[key]"
                      />
                    </template>
                    <template v-else>
                      <v-btn icon @click="deleteRow(item.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </template>
                  <template v-else>
                    <b>{{ item[key] }}</b>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialogRegisterMass = false">
          <b>Cerrar</b>
        </v-btn>
        <v-btn color="success" text @click="registerMass()"
          ><b>Registrar datos</b>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
    <script>
import { read, utils } from "xlsx";
import MessageError from "@/components/Message_Error.vue";
export default {
  name: "RegisterMass",
  props: ["specialtyRow"],
  components: {
    MessageError,
  },
  data() {
    return {
      dialogRegisterMass: false,
      headers: [
        { text: "#", value: "id" },
        { text: "Dni", value: "dni" },
        { text: "Nombre", value: "name" },
        { text: "Paterno", value: "lastnameFather" },
        { text: "Materno", value: "lastnameMother" },
        { text: "Correo", value: "email" },
        { text: "Celular", value: "phone" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      formData: [],
    };
  },
  created() {},
  methods: {
    async registerMass() {
      // Ocultar mensaje de error al volver a registrar
      this.$refs.messageError.hideAlertError();
      // Copia del arreglo original para volver a enviar solo lo que se tuvo que corregir
      let arraytemp = this.formData.slice();
      for (let i = 0; i < arraytemp.length; i++) {
        try {
          await this.registerRow(arraytemp[i]);
          this.formData.shift();
        } catch (error) {
          this.$toast.error("Error al registrar estudiante.");
          return;
        }
      }
      this.dialogRegisterMass = false;
      this.$toast.success("Se finalizo correctamente el registro masivo");
    },
    async registerRow(row) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(
            "/academic-cycle/" +
              this.$route.params.idCycle +
              "/speciality/" +
              this.specialtyRow.id +
              "/enroll",
            row,
            {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .then((response) => {
            this.$toast.success(response.data.content + " para " + row.dni);
            resolve(response);
          })
          .catch((error) => {
            this.$refs.messageError.showAlertError(error.response.data.content);
            reject(error);
          });
      });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
        //
        this.validateData(jsonData);
        //
      };

      reader.readAsArrayBuffer(file);
    },
    validateData(jsonData) {
      let array = [];
      if (jsonData.length > 0) {
        //const sizeRow = jsonData[0].length;
        for (let i = 1; i < jsonData.length; i++) {
          if (jsonData[i][0] == null) break;
          else {
            array.push({
              id: i,
              dni: jsonData[i][0],
              name: jsonData[i][1],
              lastnameFather: jsonData[i][2],
              lastnameMother: jsonData[i][3],
              email: jsonData[i][4],
              phone: jsonData[i][5],
              actions: "",
            });
          }
        }
        this.formData = array;
      } else {
        //console.log("Archivo vacio");
      }
      //console.log(array);
    },
    startDialog() {
      this.formData = [];
      this.$nextTick(() => {
        if (this.$refs.messageError) {
          this.$refs.messageError.hideAlertError();
        }
      });
    },
    //////////////////////
    deleteRow(id) {
      // Lógica para eliminar la fila con el ID proporcionado
      const index = this.formData.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.formData.splice(index, 1);
      }
    },
  },
};
</script>