<template>
  <v-app id="LoginMain">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="8" lg="5">
            <v-card class="elevation-12">
              <v-window>
                <v-window-item>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-card-text class="mt-12">
                        <h1 class="text-center display-1 font-weight-bold primary--text">Sistema Financiero - Segunda Especialidad Fips Unsa</h1>
                        <h2 class="text-center headline font-weight-bold mt-2">Inicio de Sesión</h2>
                        <h4 class="text-center mb-2">
                          Ingrese su DNI y contraseña
                        </h4>
                        <v-alert
                          v-if="AlertView"
                          class="text-left"
                          border="left"
                          color="error"
                          dismissible
                          icon="mdi-alert-circle"
                          type="success"
                          >{{ AlertMessage }}</v-alert
                        >
                        <v-form>
                          <v-text-field
                            label="Número de DNI"
                            name="dni"
                            v-model="form.username"
                            :rules="[
                              (v) => !!v || 'Este campo es requerido',
                              (v) =>
                                (v && v.length >= 6) ||
                                'La longitud mínima es de 6 caracteres',
                            ]"
                            prepend-icon="mdi-account"
                            type="text"
                            color="primary"
                            clearable
                          />

                          <v-text-field
                            label="Contraseña"
                            name="password"
                            v-model="form.password"
                            :rules="[
                              (v) => !!v || 'Este campo es requerido',
                              (v) =>
                                (v && v.length >= 6) ||
                                'La longitud mínima es de 6 caracteres',
                            ]"
                            prepend-icon="mdi-lock"
                            color="primary"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="showPassword = !showPassword"
                            @keyup.enter="login()"
                            :type="showPassword ? 'text' : 'password'"
                            hint="Haz clic en el icono del ojo para mostrar u ocultar la contraseña"
                            clearable
                          />
                        </v-form>
                      </v-card-text>
                      <div class="text-center mb-lg-5">
                        <v-btn @click="login()" color="primary" dark
                          >INGRESAR</v-btn
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" md="4" class="primary">
                      <v-card-text class="white--text mt-lg-14 mt-md-14">
                        <h1 class="text-center headline font-weight-bold">ENLACE</h1>
                        <h3 class="text-center my-2">Visita nuestra página WEB</h3>
                        <div class="text-center">
                          <v-btn color="secondary" href="https://fips.unsa.edu.pe/usefc/"
                            >IR AL SITIO</v-btn
                          >
                        </div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
  
  <script>
import router from "@/router";
export default {
  name: "LoginMain",
  data() {
    return {
      showPassword: false,
      AlertView: false,
      AlertMessage: "",
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      this.AlertView = false;
      await this.$axios
        .post("/auth/login", this.form)
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("authenticated", true);
          localStorage.setItem("key", response.data.key);
          localStorage.setItem(
            "information",
            JSON.stringify(response.data.information)
          );
          router.push({ name: "dashboard" });
        })
        .catch((error) => {
          this.AlertView = true;
          this.AlertMessage = error.response.data.content;
        });
    },
  },
};
</script>